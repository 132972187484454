<template>
  <el-row>
    <el-col :span="24">
      <div class="divider"></div>
    </el-col>
  </el-row>
  <el-row>
    <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="18">
      <el-row>
        <el-col class="aboutLeft" :xs="8" :sm="8" :md="8" :lg="7" :xl="7">
          <div ref="element" class="affix-container">
            <el-anchor ref="anchor" :marker="false" :offset="70" @change="changeFunc" @click="anchorClick">
              <div class="menu-row-1">
                <h5 class="mb-2">建造划线机器人</h5>
                <div class="el-menu-line"></div>
                <el-anchor-link href="#introduction1">
                  <el-image v-if="affix=='#introduction1' || ('/productInfo/1' === activeIndex && affix === '')" style="width: 12px;margin: 0 20px;" :src="require(`@/assets/home/images_left_change.png`)" fit="contain" />
                  <span>产品介绍</span>
                </el-anchor-link>
                <el-anchor-link href="#parameters1">
                  <el-image v-if="affix=='#parameters1'" style="width: 12px;margin: 0 20px;" :src="require(`@/assets/home/images_left_change.png`)" fit="contain" />
                  <span>产品参数</span>
                </el-anchor-link>
                <el-anchor-link href="#application1">
                  <el-image v-if="affix=='#application1'" style="width: 12px;margin: 0 20px;" :src="require(`@/assets/home/images_left_change.png`)" fit="contain" />
                  <span>应用场景</span>
                </el-anchor-link>
                <el-anchor-link href="#cases1">
                  <el-image v-if="affix=='#cases1'" style="width: 12px;margin: 0 20px;" :src="require(`@/assets/home/images_left_change.png`)" fit="contain" />
                  <span>应用案例</span>
                </el-anchor-link>
              </div>
              <div class="menu-row-2">
                <h5 class="mb-2">球场划线机器人</h5>
                <div class="el-menu-line"></div>
                <el-anchor-link href="#introduction2">
                  <el-image v-if="affix=='#introduction2' || ('/productInfo/2' === activeIndex && affix === '')" style="width: 12px;margin: 0 20px;" :src="require(`@/assets/home/images_left_change.png`)" fit="contain" />
                  <span>产品介绍</span>
                </el-anchor-link>
                <el-anchor-link href="#parameters2">
                  <el-image v-if="affix=='#parameters2'" style="width: 12px;margin: 0 20px;" :src="require(`@/assets/home/images_left_change.png`)" fit="contain" />
                  <span>产品参数</span>
                </el-anchor-link>
                <el-anchor-link href="#application2">
                  <el-image v-if="affix=='#application2'" style="width: 12px;margin: 0 20px;" :src="require(`@/assets/home/images_left_change.png`)" fit="contain" />
                  <span>应用场景</span>
                </el-anchor-link>
              </div>
            </el-anchor>
          </div>
        </el-col>
        <el-col :xs="16" :sm="15" :md="15" :lg="14" :xl="14">
          <router-view />
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>

export default {
  data () {
    return {
      activeIndex: this.$route.path,
      affix: this.$route.hash,
      fixedTop: 0,
      fixedWidth: 0,
      top: 0,
      windowWidth: 0,
      windowWidthNew: 0
    }
  },
  // watch: {
  //   $route: {
  //     handler () {
  //     }
  //   }
  // },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
    const element = this.$refs.element
    const rect = element.getBoundingClientRect()
    this.top = rect.top
    this.fixedWidth = element.offsetWidth
    this.windowWidth = window.innerWidth
    this.windowWidthNew = window.innerWidth
    console.log('元素顶部位置:', rect)
    this.activeIndex = this.$route.path
    window.addEventListener('resize', this.handleResize)
    this.changeAisactive()
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    anchorClick (e, href) {
      console.log('anchorClick href', href)
      let pathurl = '/productInfo/1'
      if (href === '#parameters2' || href === '#introduction2' || href === '#application2') {
        pathurl = '/productInfo/2'
      }
      this.activeIndex = pathurl
      this.affix = href
      console.log('anchorClick path:', pathurl)
      setTimeout(() => {
        this.$router.push({ path: pathurl, hash: href })

        setTimeout(() => {
          this.$refs.anchor.scrollTo(href)
        }, 100)
      }, 100)
    },
    changeAisactive () {
      let dom
      if (this.activeIndex === '/productInfo/1') {
        dom = document.querySelector("a[href='#introduction1']")
      } else if (this.activeIndex === '/productInfo/2') {
        dom = document.querySelector("a[href='#introduction2']")
      }
      console.log(this.activeIndex + 'changeAisactive' + this.affix)
      console.log('changeAisactive dom', dom)
      if (this.affix !== '' && this.affix !== '#introduction1' && this.affix !== '#introduction2') {
        const dom1 = document.querySelector("a[href='#introduction1']")
        const dom2 = document.querySelector("a[href='#introduction2']")
        if (dom1) dom1.classList.remove('localActive')
        console.log('remove1', dom1)
        if (dom2) dom2.classList.remove('localActive')
        console.log('remove2', dom2)
      } else if (dom && this.affix === '') {
        console.log('add localActive')
        setTimeout(() => {
          dom.classList.add('localActive')
        }, 100)
      }
    },
    changeFunc (href) {
      this.affix = href
      this.changeAisactive()
      console.log('href', href)
    },
    handleResize () {
      // 当浏览器宽度发生变化时更新 windowWidth 的值
      this.windowWidthNew = window.innerWidth
      console.log('windowWidthNew 的值:', this.windowWidthNew)
      this.$refs.element.style.width = `${this.fixedWidth * this.windowWidthNew / this.windowWidth}px`
    },
    handleScroll () {
      this.fixedTop = window.scrollY
      const lettop = this.top
      if (this.fixedTop > lettop) {
        // 固定元素
        this.$refs.element.style.position = 'fixed'
        this.$refs.element.style.width = `${this.fixedWidth * this.windowWidthNew / this.windowWidth}px`
        this.$refs.element.style.top = '100px'
        // this.$refs.element.style.position = 'absolute'
        // this.$refs.element.style.marginTop = this.fixedTop - lettop + 'px'
      } else {
        // 恢复元素到默认位置
        this.$refs.element.style.position = 'static'
        this.$refs.element.style.marginTop = '0'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.divider{
  width: 100%;
  height: 6px;
  margin: 0 0 64px 0;
  background: linear-gradient(90deg, #D00D3A 0%, #42237C 100%);
}
::v-deep .localActive {
    background: rgba(159, 159, 160, 0.5);
    color: #FFFFFF !important;
    font-weight: bold;
}
.el-row:nth-child(2) {
  justify-content: center;
  padding: 0 auto 110px;
  .aboutLeft {
    // padding-right: 160px;
    margin-right: auto;
    .menu-row-1 {
      margin-bottom: 30px;
    }
    .mb-2 {
      font-family: 微软雅黑;
      font-weight: bold;
      font-size: var(--initial-font-size-28);
      color: #222;
      margin: 0 0 20px 0;
      text-align: left;
    }
    .el-menu-line {
      width: 100%;
      height: 4px;
      background: linear-gradient(90deg, #D00D3A 0%, #42237C 100%);
    }
  }
  ::v-deep .el-anchor {
    .el-anchor__marker {
      height: 0;
    }
    .el-anchor__list {
      padding-left: 0;
      font-size: var(--initial-font-size-20);
      .el-anchor__link {
        display: flex;
        width: 100%;
        align-items: center;
        height: var(--el-menu-item-height);
        padding: 0;
        font-size: var(--initial-font-size-20);
        color: #222;
        .el-image {
          vertical-align: middle;
          height: 12px;
        }
      }
      .el-anchor__link:hover {
        background: #f1f1f1;
      }
      .is-active {
        background: rgb(159 159 160 / 50%);
        color: #FFFFFF;
        font-weight: bold;
      }
    }
  }
  .contentDiv {
    overflow: visible;
    margin-bottom: 60px;
  }
  .contentDiv:last-child {
    margin-bottom: 0px;
  }
}
</style>
