import { createRouter, createWebHistory } from 'vue-router'
import NewsInfoView from '@/views/NewsInfoView.vue'
import ProductRouter from '@/views/ProductRouter.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('../views/ProductView.vue')
  },
  {
    path: '/productInfo',
    name: 'productInfo',
    component: ProductRouter,
    children: [
      {
        path: '1',
        component: () => import('../components/product/productInfo1.vue')
      }, {
        path: '2',
        component: () => import('../components/product/productInfo2.vue')
      }
    ]
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/NewsView.vue')
  },
  {
    path: '/newsInfo',
    name: 'newsInfo',
    component: NewsInfoView,
    children: [
      {
        path: '19',
        component: () => import('../components/news/NewsInfoView19.vue')
      },
      {
        path: '18',
        component: () => import('../components/news/NewsInfoView18.vue')
      },
      {
        path: '17',
        component: () => import('../components/news/NewsInfoView17.vue')
      },
      {
        path: '16',
        component: () => import('../components/news/NewsInfoView16.vue')
      },
      {
        path: '15',
        component: () => import('../components/news/NewsInfoView15.vue')
      },
      {
        path: '14',
        component: () => import('../components/news/NewsInfoView14.vue')
      },
      {
        path: '13',
        component: () => import('../components/news/NewsInfoView13.vue')
      },
      {
        path: '12',
        component: () => import('../components/news/NewsInfoView12.vue')
      },
      {
        path: '11',
        component: () => import('../components/news/NewsInfoView11.vue')
      },
      {
        path: '10',
        component: () => import('../components/news/NewsInfoView10.vue')
      },
      {
        path: '9',
        component: () => import('../components/news/NewsInfoView9.vue')
      },
      {
        path: '8',
        component: () => import('../components/news/NewsInfoView8.vue')
      },
      {
        path: '7',
        component: () => import('../components/news/NewsInfoView7.vue')
      },
      {
        path: '6',
        component: () => import('../components/news/NewsInfoView6.vue')
      },
      {
        path: '5',
        component: () => import('../components/news/NewsInfoView5.vue')
      },
      {
        path: '4',
        component: () => import('../components/news/NewsInfoView4.vue')
      },
      {
        path: '3',
        component: () => import('../components/news/NewsInfoView3.vue')
      },
      {
        path: '2',
        component: () => import('../components/news/NewsInfoView2.vue')
      },
      {
        path: '1',
        component: () => import('../components/news/NewsInfoView1.vue')
      }
    ]
  },
  {
    path: '/honor',
    name: 'honor',
    component: () => import('../views/HonorView.vue')
  },
  {
    path: '/conact',
    name: 'conact',
    component: () => import('../views/ConactView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  // 在跳转前滚动到页面顶部
  window.scrollTo(0, 0)
  next()
})

export default router
