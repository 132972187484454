<template>
  <el-menu
    :default-active="activeIndex"
    class="el-menu-demo"
    mode="horizontal"
    router="true"
  >
    <el-menu-item>
      <img
        style="width: 387px"
        src="@/assets/home/01.png"
        alt="logo"
      />
    </el-menu-item>
    <el-menu-item index="/">首页</el-menu-item>
    <el-menu-item index="/about">关于固工</el-menu-item>
    <el-menu-item index="/productInfo/1">产品中心</el-menu-item>
    <el-menu-item index="/news">新闻中心</el-menu-item>
    <el-menu-item index="/honor">资质荣誉</el-menu-item>
    <el-menu-item index="/conact">联系我们</el-menu-item>
    <el-menu-item >
      <el-tooltip :visible="visible">
        <template #content>
          <span>正在建设中</span>
        </template>
        <img
          style="width: 82px"
          src="@/assets/home/images_top_english.png" @click="visible = true" @mouseleave="visible = false"
        />
      </el-tooltip>
    </el-menu-item>
  </el-menu>
</template>

<script>
export default {
  name: 'menuComponent',
  data () {
    return {
      activeIndex: '/',
      visible: false
    }
  },
  watch: {
    $route: {
      handler () {
        this.activeIndex = this.$route.path
        if (this.activeIndex.startsWith('/productInfo')) {
          this.activeIndex = '/productInfo/1'
        }
        if (this.activeIndex.startsWith('/about')) {
          this.activeIndex = '/about'
        }
        if (this.activeIndex.startsWith('/newsInfo')) {
          this.activeIndex = '/news'
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.el-menu--horizontal {
  --el-menu-horizontal-height: 68px;
  font-size: var(--initial-font-size-18);
  li:nth-child(2) {
    width: 96px;
  }
}
.el-menu--horizontal {
  border-bottom: none!important;
  padding: 0 60px!important;
  .el-menu-item {
    color: #222;
    font-size: var(--initial-font-size-16);
  }
}
.el-menu--horizontal > .el-menu-item:nth-child(1) {
  margin-right: 100px!important;
}
.el-menu--horizontal > .el-menu-item:last-child {
  position: absolute;
  right: 60px!important;
}
.el-menu--horizontal>.el-menu-item.is-active {
    border-bottom: none !important;
    background-color: #004EA8;
    color: #fff !important;
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  color: #222;
}
</style>
