<template>
<el-row class="news-info">
  <div class="tag"></div>
  <!-- <el-affix :offset="0"> -->
  <el-col :xs="23" :sm="23" :md="18" :lg="18" :xl="18" class="top">
    <div class="left"><div ref="element" class="buttondiv"><span @click="goback" ><el-icon><ArrowLeft /></el-icon> 返回新闻中心</span></div></div>
    <div class="right"><span @click="goback">您当前位置：新闻中心 > </span> 赛事级球场划线机器人在深圳顺利完成测试</div>
  </el-col>
  <!-- </el-affix> -->

  <el-col :span="24">
    <el-row class="Router">
      <el-col :xs="23" :sm="23" :md="11" :lg="11" :xl="11" class="contentRouter">
        <router-view/>
      </el-col>
    </el-row>
  </el-col>

  <el-col :span="24">
    <el-row class="footerone">
      <el-col :xs="23" :sm="23" :md="11" :lg="11" :xl="11" class="footer">
        <div class="left">
          <div v-if="lastLevel < 19" class="title">上一篇 <span @click="read('up')">{{ shareTitles[lastLevel+1] }}</span></div>
          <div v-if="lastLevel > 1" class="title">下一篇 <span @click="read('down')">{{ shareTitles[lastLevel-1] }}</span></div>
        </div>
        <div class="right">
          <el-popover
            :width="300"
            placement="bottom-end"
            popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 20px;"
          >
            <template #reference>
              <div><el-icon><Share /></el-icon>分享</div>
            </template>
            <template #default>
              <div class="share">
                <el-popover placement="top" :width="120" trigger="hover">
                  <template #reference>
                    <el-image style="width: 100%;" :src="require(`@/assets/news/fenxiang (1).jpg`)" fit="contain" />
                  </template>
                  <div class="qrcode">
                    <div id="qrcode"></div>
                    <div class="text">微信扫码分享</div>
                  </div>
                </el-popover>
                <el-image @click="share_weibo" style="width: 100%;" :src="require(`@/assets/news/fenxiang (2).jpg`)" fit="contain" />
                <el-image @click="shareToQQ" style="width: 100%;" :src="require(`@/assets/news/fenxiang (3).jpg`)" fit="contain" />
                <el-image @click="shareToRoom" style="width: 100%;" :src="require(`@/assets/news/fenxiang (4).jpg`)" fit="contain" />
                <el-image @click="shareDouban" style="width: 100%;" :src="require(`@/assets/news/fenxiang (5).jpg`)" fit="contain" />
              </div>
            </template>
          </el-popover>
        </div>
      </el-col>
    </el-row>
  </el-col>
</el-row>
</template>

<script>
import QRCode from 'qrcodejs2'

export default {
  name: 'NewsInfoView',
  data () {
    return {
      shareTitles: {
        19: '湖南农业大学卓越工程师学院正式揭牌，公司创始人受聘企业导师',
        18: '赛事级球场划线机器人在深圳顺利完成测试',
        17: '固工机器人参展新加坡建筑机器人与自动化技术交流会',
        16: '固工机器人与香港&澳门独家经销商签约',
        15: '新加坡建设局局长Tan Chee Kiat率政企30人考察团考察公司',
        14: '固工机器人与台湾经销商签约',
        13: '中国工程建设标准化协会智能建造工作委员会成立',
        12: '固工机器人荣获“标志性创新产品”奖',
        11: '固工机器人荣登2023年度“毕马威中国领先不动产科技企业50”榜单',
        10: '固工机器人被认定为“长沙市智能建造产业基地重点扶持企业”',
        1: '固工机器人加入中国房协智慧建筑研究中心',
        2: '湖南湘江新区管委会主任何朝晖走访调研固工机器人',
        3: '我国首部《智能建造评价标准》正式启动编制',
        4: '第八届工程建设行业互联网大会－智能建造专题会议召开',
        5: '固工机器人与芜湖城市建设集团达成战略合作',
        6: '固工机器人与安可美达成战略合作',
        7: '长沙市绿色建筑发展中心和湖南省建筑设计院领导到访固工机器人',
        8: '“中建固工机器人联合研发中心”正式成立',
        9: '我司建造划线作业机器人完成国家会议中心二期项目施工'
      },
      lastLevel: 0,
      share: {
        title: '分享测试',
        desc: '测试描述',
        share_url: 'https://xiucai.neafex.com/#/'
      }
    }
  },
  watch: {
    $route: {
      handler () {
        this.initParams()
      }
    }
  },
  mounted () {
    this.initParams()
    window.addEventListener('scroll', this.handleScroll)
    const element = this.$refs.element
    const rect = element.getBoundingClientRect()
    this.top = rect.top
    this.fixedWidth = element.offsetWidth
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    initParams () {
      this.share.share_url = process.env.VUE_APP_API_BASE_URL + this.$route.path
      const pathid = this.$route.path.split('/').pop()
      const regex = /\d+/g // 匹配一个或多个数字
      const matches = pathid.match(regex)
      this.lastLevel = parseInt(matches[0])
      console.log('lastLevel', this.lastLevel)
      this.share.title = this.shareTitles[this.lastLevel]
      this.share.desc = this.share.title
      document.getElementById('qrcode').innerHTML = ''
      // 调用生成二维码的方法
      this.generateQRCode('qrcode')
    },
    handleScroll () {
      this.fixedTop = window.scrollY
      const lettop = this.top * 2
      if (this.fixedTop > lettop) {
        console.log('this.fixedTop', this.fixedTop)
        console.log('this.top', this.top)
        // 固定元素
        this.$refs.element.style.position = 'fixed'
        // this.$refs.element.style.width = `${this.fixedWidth * this.windowWidthNew / this.windowWidth}px`
        this.$refs.element.style.top = '100px'
        // this.$refs.element.style.position = 'absolute'
        // this.$refs.element.style.marginTop = this.fixedTop - lettop + 'px'
      } else {
        // 恢复元素到默认位置
        this.$refs.element.style.position = 'static'
        this.$refs.element.style.marginTop = '0'
      }
    },
    read (type) {
      if (type === 'up') {
        this.$router.push({ path: '/newsInfo/' + (this.lastLevel + 1) })
      } else {
        this.$router.push({ path: '/newsInfo/' + (this.lastLevel - 1) })
      }
    },
    goback () {
      this.$router.push({ path: '/news' })
    },
    generateQRCode (domid) {
      const qrcode = new QRCode(domid, {
        text: process.env.VUE_APP_API_BASE_URL + this.$route.path, // 这里是你想要生成二维码的内容
        width: 120,
        height: 120,
        colorDark: '#000000',
        colorLight: '#ffffff'
      })
      console.log(qrcode)
    },
    shareDouban () {
      // 此处分享链接内无法携带图片
      window.open(
        'https://www.douban.com/share/service?url=' +
          encodeURIComponent(this.share.share_url) +
          '&name=' +
          this.share.title
      )
    },
    // 分享到QQ
    shareToQQ () {
      // 此处分享链接内无法携带图片
      window.open(
        'https://connect.qq.com/widget/shareqq/index.html?url=' +
          encodeURIComponent(this.share.share_url) +
          '&title=' +
          this.share.title +
          '&desc=' +
          this.share.desc
      )
    },
    shareToRoom () {
      // 跳转地址
      window.open(
        'https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=' +
          encodeURIComponent(this.share.share_url) +
          '&title=' +
          this.share.title +
          '&desc=' +
          this.share.desc,
        '_blank',
        'height=100, width=400',
        'scrollbars=yes,resizable=1,modal=false,alwaysRaised=yes'
      )
    },
    // 分享到新浪微博
    share_weibo () {
      // shareUrl是微博的分享地址，（有资料说需要真实的appkey，必选参数，这里我没用appkey也可以正常分享）
      var _shareUrl = 'http://service.weibo.com/share/share.php?'
      _shareUrl += 'url=' + encodeURIComponent(this.share.share_url) // 参数url设置分享的内容链接
      _shareUrl += '&title=' + encodeURIComponent(this.share.title) // 参数title设置分享的标题
      // 保留当前页面,打开一个非tab页面（按需求来，可以新开标签页，也可以在当前页新开页面）
      window.open(
        _shareUrl,
        '_blank',
        'height=100, width=400',
        'scrollbars=yes,resizable=1,modal=false,alwaysRaised=yes'
      )
    }
  }
}
</script>

<style scoped lang="scss">
.news-info {
  width: 100%;
  justify-content: center;
  .tag {
    content: ' ';
    width: 100%;
    height: 6px;
    background: linear-gradient(90deg, #D00D3A 0%, #42237C 100%);
  }
  .Router {
    justify-content: center;
    .contentRouter {
      margin: 0 0 50px;
      border-bottom: 1px solid #9F9FA0;
      padding-bottom: 20px;
      line-height: 1.5;
    }

  }
  .top {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    font-size: var(--initial-font-size-20);
    text-align: left;
    margin: 40px 200px 50px;
    padding-bottom: 20px;
    border-bottom: 1px solid #EEEEEE;
    color: #A3A3A3;
    .right {
      text-align: right;
      color: #242424;
      span {
        color: #A3A3A3;
        align-items: center;
      }
    }
    .left {
      .buttondiv {
        z-index: 9;
        width: 170px;
        background-color: #F1F1F1;
        padding: 14px 16px;
        border-radius: 10px;
        span {
          display: flex;
          align-items: center;
          margin-left: 10px;
        }
      }
    }
  }
  .footerone {
    justify-content: center;
    .footer {
      display: grid;
      grid-template-columns: auto auto;
      margin: 0 0 50px;
      text-align: left;
      align-items: center;
      .right {
        text-align: right;
        display: flex;
        flex-flow: row-reverse;
        i {
              margin-right: 5px;
        }
      }
      .left {
      .title {
          margin-top: 20px;
        }
        span:hover {
          cursor: pointer;
          color: #004EA8;
        }
      }
    }
  }
}
.share {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-gap: 20px;
}
.qrcode {
  #qrcode {
    justify-items: center;
    justify-content: center;
  }
  .text {
    margin: 10px 0 0 0;
    text-align: center;
  }
}
</style>
