<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav>
  <router-view/> -->

  <el-container id="mainTop">
    <el-affix :offset="0">
      <el-header>
        <MenuComponent />
      </el-header>
    </el-affix>
    <el-main>
      <router-view/>
      <div class="salber">
        <div class="item">
          <el-image style="width: 100%;vertical-align: bottom;" :src="require(`@/assets/home/WechatIMG747.jpg`)" fit="contain" />
          <div class="title">
            关注公众号
          </div>
          <div class="title">
            了解更多↑
          </div>
        </div>
        <div class="item">
          <el-image style="vertical-align: bottom;" :src="require(`@/assets/home/ico_a_09.png`)" fit="contain" />
          <div class="title">
            138-0749-0707
          </div>
          <div class="title">
            电话咨询
          </div>
        </div>
        <div class="item">
          <a href="#mainTop" >
            <el-image style="vertical-align: bottom;" :src="require(`@/assets/home/ico_a_15.png`)" fit="contain" />
            <div class="title">
                返回顶部
            </div>
          </a>
        </div>
      </div>
    </el-main>
    <el-footer>
      <FooterComponent />
    </el-footer>
  </el-container>
</template>

<script>
import MenuComponent from '@/components/menu.vue'
import FooterComponent from '@/components/footer.vue'

export default {
  name: 'app',
  components: {
    MenuComponent,
    FooterComponent
  }
}
</script>

<style lang="scss" scoped>
#app {
  font-family: 微软雅黑;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.el-header, .el-footer {
  background-color: #FFFFFF;
  color: #333;
  text-align: center;
  padding: 0;
}

.el-main {
  color: #333;
  text-align: center;
  // line-height: 160px;
  padding: 0;
}
.el-container {
  @media (max-width: 1600px) {
    --base-font-size: 20px;
    --initial-font-size-22: 20px;
    --initial-font-size-20: 18px;
    --initial-font-size-28: 24px;
    --initial-font-size-36: 28px;
    --initial-font-size-18: 16px;
    --initial-font-size-16: 14px;
  }
  @media (max-width: 1280px) {
    --base-font-size: 18px;
    --initial-font-size-22: 18px;
    --initial-font-size-20: 16px;
    --initial-font-size-28: 22px;
    --initial-font-size-36: 24px;
    --initial-font-size-18: 16px;
    --initial-font-size-16: 14px;
  }
  @media (max-width: 980px) {
    --base-font-size: 16px;
    --initial-font-size-22: 16px;
    --initial-font-size-20: 14px;
    --initial-font-size-28: 18px;
    --initial-font-size-36: 20px;
    --initial-font-size-18: 14px;
    --initial-font-size-16: 12px;
  }
  --base-font-size: 24px;
  --initial-font-size-22: 22px;
  --initial-font-size-20: 20px;
  --initial-font-size-28: 28px;
  --initial-font-size-36: 36px;
  --initial-font-size-18: 18px;
  --initial-font-size-16: 16px;
  --initial-news-top: 10px;
  font-family: 微软雅黑;
  .salber {
    position: fixed;
    top: 30%;
    right: 0;
    z-index: 9;
    width: 100px;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    font-size: 13px;
    box-shadow: 0px 1px 33px 2px rgba(2,0,0,0.18);
    .item {
      padding-bottom: 15px;
      margin-bottom: 20px;
      border-bottom: 1px solid rgb(225 221 218 / 50%);
      a {
        text-decoration: none;
        color: #121933;
      }
    }
    .item:last-child {
      border-bottom: none;
    }
  }
}
.el-header {
  --el-header-height: 68px;
}

// 响应式调整
@media (min-width: 1700px) {
  .el-container {
    width: 1660px;
    margin: 0 auto;
  }
}

// 响应式调整
@media (max-width: 768px) {
  .el-container {
    width: 100%;
  }
}
</style>
