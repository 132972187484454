<template>
  <div class="footer">
    <el-row>
      <el-col class="footerLogo" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <el-image style="width: 40%;" :src="require(`@/assets/home/03.png`)" fit="contain" />
      </el-col>
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <div class="footer-info">
          <a href="https://beian.mps.gov.cn/#/query/webSearch?code=43019002002036" target="_blank">湘公网安备43019002002036号  ©2019 版权所有</a>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'footerComponent'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.footer {
  background: #222;
  display: flex;
  justify-content: space-between;
  line-height: 60px;
  padding: 0 140px;
  .el-col {
    display: flex;
  }
 .footer-info {
    margin-left: auto;
    a {
      color: #fff;
      text-decoration: none;
      font-weight: 400;
    }
  }

  // 响应式调整
  @media (max-width: 1000px) {
    .el-image {
      margin: auto; // 小屏幕时图片宽度占满整个列
    }
    .footer-info {
      margin: auto;
    }
    .footerLogo {
      margin-top: 30px;
    }
  }

  // 响应式调整
  @media (max-width: 768px) {
    padding: 0 20px; // 小屏幕时减少内边距
   .footer-info {
      font-size: 0.8em; // 小屏幕时缩小字体大小
    }
  }
}
</style>
